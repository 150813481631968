<template>
    <v-expansion-panel>
      <block v-if="loading"></block>
      <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
      </alerts>
      <v-expansion-panel-header class="mt-2">
        Plantillas de Vacunas/Medicamentos
        <template v-slot:actions>
          <v-icon color="primary" @click.native.stop="reloadp++;getMedicationPlans();cleanMedicationPlan()">
            mdi-refresh
          </v-icon>
          <v-icon color="error" @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })">
            mdi-close
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content :key="reloadp">
        <v-card>
          <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
            <v-tabs-slider color="red"></v-tabs-slider>
            <v-tab href="#tab-1">
             Plantillas de vacunas/medicamentos
              <v-icon>mdi-pencil-circle</v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" touchless>
            <v-tab-item value="tab-1">
              <v-card flat>
                <v-card-text>
                  <div>
                    <ValidationObserver ref="observer">
                      <v-row>
                        <v-col   cols="12">
                          <div :class="!showLabHistory ? '':'box'">
                      <div  class="tabs"  @click="showLabHistory = !showLabHistory" :class="showLabHistory ? ' ':'box'"  >
                      <v-icon v-if="showLabHistory" color="white" left>
                        mdi-minus
                      </v-icon>
                      <v-icon v-else color="white" left> mdi-plus </v-icon>
                      Historial de Laboratorio
          </div>
                          <div v-if="showLabHistory" >
                           
                            <p class="pt-5 text-h6 mb-0 ml-2">
                             Administrar Plantillas de vacunas/medicamentos
                            </p>
                            <!-- Needs filter -->
                            <DxDataGrid class="isScrolledByGeneralFilter" :data-source="planList" key-expr="ID_Medication_Plan"
                              :show-row-lines="showRowLines" :show-borders="showBorders"
                              :row-alternation-enabled="rowAlternationEnabled">
                              <DxScrolling row-rendering-mode="virtual" />
                              <DxPaging :page-size="10" />
                              <DxColumn :width="60" cell-template="show-template" caption=""></DxColumn>
                              <DxColumn data-field="ID_Medication_Plan" caption="ID de Plantilla de Medicamentos"></DxColumn>
                              <DxColumn data-field="PlanName" caption="Nombre de la Plantilla"></DxColumn>
                              <DxColumn :width="60" cell-template="delete-template" caption=""></DxColumn>
                              <template #show-template="{ data }">
                                <a ref="details" href="javascript:void(0)" @click.prevent.stop="
                                  showMedicationPerameter(data.data)
                                ">
                                  <v-icon color="primary" small>mdi-eye</v-icon>
                                </a>
                              </template>
                              <template #delete-template="{ data }">
                                <a href="javascript:void(0)" @click.prevent="
                                  planToDelete = data.data;
                                showDelete()
                                ">
                                  <v-icon small color="error">mdi-delete</v-icon>
                                </a>
                              </template>
                            </DxDataGrid>
                          </div>
                          </div>
                        </v-col>

                        <v-col cols="12">
                          <div
                            style="background-color: white;  border-style: solid;border-color: black; border-width: 1px; padding: 1rem; border-radius: 15px; margin: 0.5rem;">
                            
                            <v-row>
                              <v-col cols="12">
                                <p class="text-h6" v-if="form.ID_Medication_Plan">Modificar plantilla de vacunas/medicamentos</p>
                                <p class="text-h6" v-else>Agregar plantilla de vacunas/medicamentos</p>
                              </v-col>
                              
                              
                                <v-col cols="6">
                                  <ValidationProvider name="Nombre plan de medicamentos" rules="required" v-slot="{ errors }">
                                    <v-text-field outlined dense :error-messages="errors" v-model="form.PlanName"
                                      label="Nombre de plantilla"></v-text-field>
                                  </ValidationProvider>
                                </v-col>
                                <v-col>
                                  <v-btn @click.prevent.stop="cleanMedicationPlan"
                                      class="btnclean">
                                      Limpiar
                                    </v-btn>
                                </v-col>
                              <v-col cols="12">
                                 
                                                              
                                                              <quill-editor label="Comentarios" :content="form.Comment" :options="editorOption"
                                 v-model="form.Comment"></quill-editor>
                              </v-col>
                             <v-col cols="12" style="display: flex;justify-content: flex-end;">
                               
                               <v-btn class="mt-2 btnsave mb-4" v-if="form.ID_Medication_Plan"
                                 @click.prevent.stop="updateMedicationPlan">
                                 Actualizar
                               </v-btn>
                               <v-btn v-else class="mt-2 btnsave mb-4" large @click.prevent.stop="saveMedicationPlan">
                                 Agregar registro
                               </v-btn>
                               <br/>
                             </v-col>
                            </v-row>
                          </div>
                        </v-col>
                        <v-col cols="12">
                          <div
                            style="padding: 16px;background-color: white;  border-style: solid;border-color: black; border-width: 1px; padding: .5rem; border-radius: 15px; margin: 0.5rem;">
                           
                           
                            <v-col lg="12" class="mt-5">
                              <v-row  style="color: black;">
                              <v-col cols="12">
                                <span class="text-h6">{{ "Plantilla seleccionada" }}:</span>
                                <span v-if="selectedPerameter.PlanName" class="text-h6" >
                                  {{ selectedPerameter.PlanName }}
                                </span>
                                <!--  v-html="selectedPerameter.Comment" -->
                                <span v-else class="text-h6">
                                  {{ "No se ha seleccionado ninguna plantilla" }}
                                </span>
                              </v-col>
                              
                            </v-row>
                              <v-col cols="12" style="display: flex;justify-content: flex-end;">
                              <v-btn class=" btn-add" small @click.prevent.stop="
                                addMedicationDialog = !addMedicationDialog
                              " v-if="selectedPerameter.ID_Medication_Plan">
                                <v-icon color="white"> mdi-plus </v-icon>
                              </v-btn>
                            </v-col>
                              <DxDataGrid class="isScrolledByGeneralFilter" id="gridContainer" :data-source="ParameterList"
                                :allow-column-reordering="true" :show-borders="true" key-expr="ID_Plan_Perameter"
                                :confirm-delete="false"
                                @row-updated="UpdatePlansRow" @row-removed="DeletePlansRow"
                               >  <DxColumn
                  
                  caption="Orden"
                   data-field="Order"
                />
                                <DxPaging :enabled="false" />

                                <DxEditing :allow-updating="true" :allow-deleting="true" :allow-adding="false"
                                :confirmDelete="false"
                                :select-text-on-edit-start="true"
                            start-edit-action="click"
                                 :use-icons="true"
                                  mode="cell" />
                                  <DxKeyboardNavigation
                            :edit-on-key-press="true"
                            enter-key-action="moveFocus"
                            enter-key-direction="column"
                          />
                                <DxColumn data-field="Medicine" caption="Nombre" :allow-editing="false" />

                                <DxColumn data-field="PeriodTime" caption="Período" data-type="number">
                                </DxColumn>
                                <DxColumn data-field="PeriodUnit" caption="Unidad de tiempo">
                                  <DxLookup :data-source="items" display-expr="Name" value-expr="Value" />

                                </DxColumn>
                                <DxColumn cell-template="item-type" caption="Tipo"  :allow-editing="false" />
                                 
                                 <template #item-type="{data}">
                                    <div>
                                      <label v-if="data.data.ItemType == 'VAC'">Vacuna</label>
                                      <label v-if="data.data.ItemType == 'MED'">Medicamento</label>
                                    </div>
                                 </template>
                              </DxDataGrid>
                             
                                <v-col v-if="selectedPerameter.ID_Medication_Plan" cols="12" style="display: flex;justify-content: flex-end;">
                                  <v-btn class="mt-2 btnsave" large @click.prevent.stop="updatePlanParameters">
                                    Actualizar cambios
                                  </v-btn>
                                </v-col>
                            </v-col>
                          </div>
                        </v-col>
                      </v-row>
                    </ValidationObserver>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-expansion-panel-content>
    
      <v-dialog v-model="addMedicationDialog" scrollable
        :width="$vuetify.breakpont.smAndDown ? '90%':'80%'"
      >
        <MedicationList @refreshData="refreshData" @justRefresh="justRefresh" :id="selectedPerameter.ID_Medication_Plan" :loginId="loginUserID"
          :perameters="ParameterList"></MedicationList>
      </v-dialog>
    </v-expansion-panel>
    <!-- <v-dialog v-model="deleteDialog" max-width="500">
      <delete-template-dialog :data="planToDelete" :name="planToDelete.PlanName" v-if="deleteDialog"
        @close="closeDialog" @f_call="deleteMedicationPlan($event)"></delete-template-dialog>
    </v-dialog> -->

    
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxPaging,
  DxScrolling,
  DxLookup,
  DxKeyboardNavigation
} from "devextreme-vue/data-grid";
/* import DeleteTemplateDialog from "../business/partial/DeleteTemplateDialog.vue"; */
import alerts from "../../../components/Alerts.vue"
import MedicationList from "./MedicationListFromProduct.vue";
import block from '../../../components/Block.vue'

export default {
  name: "ManageMedicationPlans",
  props: ["win"],
  components: {
    DxKeyboardNavigation,
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    alerts,
    DxScrolling,
    DxLookup,
    MedicationList,
    block
  },

  data() {
    return {
      loading: false,
      tab: null,
      planList: [],
      ParameterList: [],
      form: {},
      rowAlternationEnabled: true,
      showRowLines: true,
      showBorders: true,
      loginUserID: null,
      planToDelete: null,
      deleteDialog: false,
      addMedicationDialog: false,
      selectedPerameter: {},
      editorOption: {
        placeholder: "Comments...!",
      },

      items: [
      {
          ID: "1",
          Name: "Dia",
          Value: "Day"
        },
        {
          ID: "2",
          Name: "Semana",
          Value: "Week"
        },
        {
          ID: "3",
          Name: "Mes",
          Value: "Month"
        },
        {
          ID: "4",
          Name: "Año",
          Value: "Year"
        },
      ],
      search: {},
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      showLabHistory: false, 
      newTable: [] 
    };
  },

  watch: {},

  mounted() {
    this.loginUserID = JSON.parse(localStorage.getItem("user")).id;
    this.search.business = JSON.parse(localStorage.getItem("user")).businessid || ""
    this.search.branch = JSON.parse(localStorage.getItem("user")).branch || ""
    this.form.ID_Business = this.search.business
    this.form.ID_Branch = this.search.branch
    this.getMedicationPlans();
  },

  methods: {
    orderParams(array) {
      array.sort(function(a, b) {
    if ((a["Order"] === "" || isNaN(parseInt(a["Order"]))) && (b["Order"] !== "" && !isNaN(parseInt(b["Order"])))) {
      return 1;
    } else if ((a["Order"] !== "" && !isNaN(parseInt(a["Order"]))) && (b["Order"] === "" || isNaN(parseInt(b["Order"])))) {
      return -1;
    } else if ((a["Order"] === "" || isNaN(parseInt(a["Order"]))) && (b["Order"] === "" || isNaN(parseInt(b["Order"])))) {
      return 0;
    } else if (parseInt(a["Order"]) === parseInt(b["Order"])) {
      return 0;
    } else {
      return (parseInt(a["Order"]) < parseInt(b["Order"])) ? -1 : 1;
    }
  });
  return array;
},


    showDelete() {
      this.showAlert("question", "Eliminar Paciente", "¿Está seguro que desea eliminar este plan de medicamentos?", "deleteplan");
    },
    closeAlert() {
      this.alert.show = false;

    },
    acceptAlert() {
      this.alert.show = false;

      if (this.alert.options == "deleteplan") {
        this.deleteMedicationPlan(this.planToDelete);
      }
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    cleanMedicationPlan() {
      this.form = {};
      this.selectedPerameter = {};
      this.ParameterList = [];
    },
    getMedicationPlans() {
      this.$API.medication.getMedicationPlans(this.search).then(
        (res) => {
          this.planList = res;
        },
        (err) => {
          console.log(err);
        }
      );
    },

    async saveMedicationPlan() {
      this.$refs.observer.validate();
      //const isValid = await this.$refs.observer.validate();
      let data = {
        PlanName: this.form.PlanName,
        Comment: this.form.Comment,
        ID_User_Insert: this.loginUserID,
        ID_Business:  JSON.parse(localStorage.getItem("user")).businessid || "",
        ID_Branch: JSON.parse(localStorage.getItem("user")).branch || ""
      };
      
       
        this.$API.medication.addMedicationPlan(data).then(
          (res) => {
            this.showAlert("success", "Éxito", "Plan de medicamentos guardado con éxito");
            console.log(res);
            this.clearReset()
            this.$refs.observer.reset();
          },
          (err) => {
            console.log(err);
            this.showAlert("danger", "Error", "Ha ocurrido un error al guardar el plan de medicamentos");
          }
        );
      
    },

    async updateMedicationPlan() {
      this.$refs.observer.validate();
      const isValid = await this.$refs.observer.validate();
      let data = {
        PlanName: this.form.PlanName,
        Comment: this.form.Comment,
        ID_User_Insert: this.loginUserID,
        ID_Business: this.form.ID_Business,
        ID_Branch: this.form.ID_Branch,
        ID_Medication_Plan: this.selectedPerameter.ID_Medication_Plan || ""
      };
      if (isValid) {
        if (!this.form.Comment) {
          this.showAlert("danger", "Advertencia", "Debe ingresar un comentario");
          return;
        }
        this.$API.medication.updateMedicationPlan(data).then(
          (res) => {
            console.log(res);
            this.showAlert("success", "Éxito", "Plan de medicamentos actualizado con éxito");
            this.clearReset()
            this.$refs.observer.reset();
          },
          (err) => {
            console.log(err);
            this.showAlert("danger", "Error", "Ha ocurrido un error al actualizar el plan de medicamentos");
          }
        );
      }
    },



    deleteMedicationPlan(del) {
      this.$API.medication.deleteMedicationPlan(del.ID_Medication_Plan).then(
        (res) => {
          /*   this.$toasted.success(res.message); */
          this.showAlert("success", "Éxito", "Plan de medicamentos eliminado con éxito");
          console.log(res);
          this.clearReset()
          this.closeDialog();
        },
        (err) => {
          console.log(err);
          this.showAlert("danger", "Error", "Ha ocurrido un error al eliminar el plan de medicamentos");
        }
      );
    },

    showMedicationPerameter(d) {
      this.loading = true;
      //add buton to update us from this param name

      //add other btn to add medication to this param
      this.selectedPerameter = { ...d }
      this.form = { ...d }
      this.$API.medication.showParametersByPlanID(this.selectedPerameter.ID_Medication_Plan).then(
        (res) => {
          this.ParameterList = res;
          this.orderParams(this.ParameterList)
          this.loading = false;
        },
        (err) => {
          console.log(err);
        }
      );
    },

    UpdatePlansRow(e) {

        this.newTable.push({data: e.data, methods: "update"})
       this.orderParams(this.ParameterList);
    /*   e.data.ID_User_Update = this.loginUserID;
      this.$API.medication
        .updateMedicationPerameter(e.data.ID_Plan_Perameter, e.data)
        .then((response) => {
          console.log(response);
          this.showAlert("success", "Éxito", "Parámetro de medicamento actualizado con éxito");
        })
        .catch((err) => {
          console.log(err);
          this.showAlert("danger", "Error", "Ha ocurrido un error al actualizar el parámetro de medicamento");
        }); */
    },

    DeletePlansRow(e) {
      this.newTable.push({data: e.data, methods: "delete"})
      /* 
      this.$API.medication.deleteMedicationPerameter(e.data.ID_Plan_Perameter).then((response) => {
        console.log(response);
        this.showAlert("success", "Éxito", "Parámetro de medicamento eliminado con éxito");
        this.refreshData()
      })
        .catch((err) => {
          console.log(err);
          this.showAlert("danger", "Error", "Ha ocurrido un error al eliminar el parámetro de medicamento");
        }); */
    },
    
    updatePlanParameters(){
      if(this.newTable.length == 0){
        this.showAlert("danger", "Advertencia", "No hay cambios para guardar");
        return
      }
      else{
        this.$API.medication
        .updateMedicationPerameter(this.newTable)
       
        .then((response) => {
          console.log(response);
          this.showAlert("success", "Éxito", "Parámetro de medicamento actualizado con éxito");
          this.clearReset()
          this.cleanMedicationPlan()
        })
        .catch((err) => {
          console.log(err);
          this.showAlert("danger", "Error", "Ha ocurrido un error al actualizar el parámetro de medicamento");
        });
      }
    }
    ,

    refreshData() {
      this.showMedicationPerameter(this.selectedPerameter)
      this.addMedicationDialog = false
    },
    justRefresh(){
      this.showMedicationPerameter(this.selectedPerameter)
    },
    closeDialog() {
      this.planToDelete = null;
      this.deleteDialog = false;
      // this.detailData = {};
      this.getMedicationPlans();
    },

    clearReset() {
      this.form = {}
      this.selectedPerameter = {}
      this.getMedicationPlans()
      this.newTable = []
    },

  },
};
</script>

<style scoped>
.box {
  border-style: solid;
  border-color: black;
  border-width: 1px;
  background-color: white;
  padding: .5rem;
  border-radius: 15px;
  margin: 0.5rem;

  
}
.tabs {

padding: 10px;
background-color: rgba(60, 60, 60, 1);
color: white;
}

.btn-add {
  background-color: #3498DB !important;
  color: white;
}

.btnsave {
  background-color: #00A178 !important;
  color: white;
}

.btnclean {
  background-color: #F29D35 !important;
  color: white;
}

.btndelete {
  background-color: #E3191E !important;
  color: white;
}

.ql-container.ql-snow {
  min-height: 100px !important;
}
</style>

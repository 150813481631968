<template>
    <v-card>
        <v-card-title class="text-h5" v-if="decision=='add'">
            {{ $t("Do_you_want_to_add") }} - {{name}} ?
        </v-card-title>

        <v-card-title class="text-h5" v-if="decision=='remove'">
            {{ $t("Do_you_want_to_remove") }} - {{name}} ?
        </v-card-title>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="performTask">
                {{ $t("Yes") }}
            </v-btn>
            <v-btn color="green darken-1" text @click="$emit('close')">
                {{ $t("No") }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "planVaccineConfirm",
        props: ['name', 'decision'],
        methods: {
            performTask() {
                this.$emit('f_call',this.decision)
            }
        }
    }
</script>

<style scoped>

</style>

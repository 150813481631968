<template>
  <div>
    <v-card>
      <v-card-title class="text-h5"> Medicamentos y vacunas </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="12">
            <!-- Needs filter -->
            <DxDataGrid class="isScrolledByGeneralFilter" :show-borders="true" :data-source="medications" key-expr="ID_Service">
              <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
              <DxColumn data-field="ID_Service" caption="Id"></DxColumn>
              <DxColumn data-field="NameService" caption="Servicio"></DxColumn>
              <DxColumn cell-template="type-service" caption="Tipo"></DxColumn>
              <template #show-template="{ data }">
                <div>
                  <!--     <v-checkbox
                    v-model="data.data.checkbox"
                    class="pt-0"
                    @click="getSelectedRow(data.data)"
                  ></v-checkbox> -->
                  <input type="checkbox" id="checkbox" @change="getSelectedRow(data.data)">
                </div>
              </template>
              <template #type-service="{ data }">
                <div>
                  <p v-if="data.data.TypeService == 'VAC'">Vacuna</p>
                  <p v-if="data.data.TypeService == 'MED'">Medicamento</p>
                </div>
              </template>
            </DxDataGrid>
            <div class="options">

              <div class="options-container">
                <div class="option">

                  <DxButton text="Contained" styling-mode="contained" style="min-width: 120px;" :height="50"
                    @click="addAll()" type="default">
                    <template #content>
                      <span>
                        <DxLoadIndicator :visible="loadIndicatorVisible" class="button-indicator" />
                        <span class="dx-button-text">Asignar Todos</span>
                      </span>
                    </template>
                  </DxButton>
                </div>
                <div class="option">

                  <DxButton text="Contained" styling-mode="contained" style="min-width: 120px;" :height="50"
                    @click="saveAssign()" type="success">
                    <template #content>
                      <span>
                        <DxLoadIndicator :visible="loadIndicatorVisible" class="button-indicator" />
                        <span class="dx-button-text">Asignar seleccionados</span>
                      </span>
                    </template>
                  </DxButton>
                </div>

                &nbsp;

              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="confirmDialog" max-width="550">
      <planVaccineConfirm :name="name" :decision="decision" v-if="confirmDialog" @close="closeDialog"
        @f_call="performfunction($event)"></planVaccineConfirm>
    </v-dialog>
  </div>
</template>

<script>
import { DxDataGrid, DxColumn } from "devextreme-vue/data-grid";
import planVaccineConfirm from "../../../components/planVaccineConfirm.vue";
import { DxButton } from 'devextreme-vue/button';
import { DxLoadIndicator } from 'devextreme-vue/load-indicator';
export default {
  name: "MedicationList",

  components: {
    DxDataGrid,
    DxColumn,
    DxButton,
    DxLoadIndicator,
    planVaccineConfirm,
  },

  props: ["id", "loginId", "perameters"],

  data() {
    return {
      medications: [],
      assignedMedications: [],
      confirmDialog: false,
      name: null,
      decision: null,
      data: [],
      loadIndicatorVisible: false,
    };
  },

  mounted() {
    this.getProductMedication();
  },

  watch: {
    perameters() {
      this.getProductMedication();
    },
    confirmDialog() {
      this.getProductMedication();
    },
  },

  methods: {
    getProductMedication() {
     let query = {
        business : JSON.parse(localStorage.getItem("user")).businessid || "",
        branch : JSON.parse(localStorage.getItem("user")).branch || ""
      };
      this.$API.medication.getMedicationInventry(query).then((medications) => {
        this.medications = medications;
        /*this.medications.map((ele) => {
          this.perameters.map((sub) => {
            if (sub.ID_Medicine == ele.ID_Item) ele.checkbox = true;
          });
        });*/
      });
    },

    getSelectedRow(data) {
      /*  this.name = data.ItemName;
       this.confirmDialog = true;
       if (data.checkbox == true) {
         this.decision = "add";
         this.data = data;
       } else {
         this.decision = "remove";
         this.data = data;
       } */
      if (this.assignedMedications.length >= 1) {
        let isIncluded = this.assignedMedications.find(e => e.ID_Service === data.ID_Service) !== undefined;
        if (isIncluded) {
          this.assignedMedications = this.assignedMedications.filter(v => v.ID_Service != data.ID_Service)
        } else {
          this.assignedMedications.push(data)
        }
      } else {
        this.assignedMedications.push(data)
      }

    
    },
    saveAssign() {
      this.assignedMedications.forEach(element => {
        this.data = element
        this.name = element.ItemName;
        this.performfunction()
        this.$emit("justRefresh");
      });

      this.closeDialog()

      this.assignedMedications = []
      this.$emit("refreshData");
    },
    addAll() {
      this.medications.forEach(element => {
        this.data = element
        this.name = element.ItemName;
        this.performfunction(element)
        this.$emit("justRefresh");
      });

      this.assignedMedications = []
      this.closeDialog()
      this.$emit("refreshData");
    },

    performfunction() {
      
      let medic = {
        ID_User_Insert: this.loginId,
        ID_Medication_Plan: this.id,
        Medicine: this.data.NameService,
        ID_Medicine: this.data.ID_Service,
        ID_Business: JSON.parse(localStorage.getItem("user")).businessid || "",
        ID_Branch : JSON.parse(localStorage.getItem("user")).branch || "",
        ItemType: this.data.TypeService,

      };
      this.$API.medication.addMedicationParameter(medic).then(
        (response) => {
          console.log(response);
          console.log("added");
        },
        (err) => {
          console.log(err);
        }
      );
      /*  if (res == "add") {
         let medic = {
           ID_User_Insert: this.loginId,
           ID_Medication_Plan: this.id,
           Medicine: this.data.ItemName,
           ID_Medicine: this.data.ID_Item,
         };
         this.$API.medication.addMedicationParameter(medic).then(
           (response) => {
             console.log(response);
           },
           (err) => {
             console.log(err);
           }
         );
       }  *//* else if (res == "remove") {
        this.perameters.map((sub) => {
          if (sub.ID_Medicine == this.data.ID_Item) {
            this.$API.medication
              .deleteMedicationPerameter(sub.ID_Plan_Perameter)
              .then((response) => {
                this.$toasted.success(response.message);
                this.$emit("refreshData");
                this.closeDialog();
              });
          }
        });
      } */

    },

    closeDialog() {
      this.confirmDialog = false;
      this.data = [];
      this.getProductMedication();
    },
  },
};
</script>

<style>
.options-container .option:last-child {
  float: right;
}
</style>
